@import '../_sass/bootstrap';

/*- Fonts -*/
%oswald { font-family: 'Oswald', sans-serif; }
%roboto { font-family: 'Roboto Condensed', sans-serif; }

/*- general stuff -*/
html { overflow-y:scroll; overflow-x:hidden; }
html.behandlingar { overflow-y:unset; overflow-x:unset; }
body { @extend %roboto; color: #222222; -webkit-font-smoothing: antialiased; }
::selection { background: $paloma-primary; color: #222222; }
a { outline: none !important; transition: all 100ms ease; color: var(--bs-gray-800); text-decoration-color: $paloma-primary; text-decoration-thickness: .125em; text-underline-offset: 1.5px;
    &:hover { color: #000; text-decoration-color: $paloma-secondary; }
    &:focus, &:active { color: $paloma-primary; }
}
h1, h2, h3, h4, h5, h6 { @extend %oswald; line-height: 1.3; }
.gold {color: $paloma-primary;}
.form-control:focus { border-color: $paloma-primary; box-shadow: 0 0 0 0.25rem rgba(191, 171, 96, .25); }
.btn-check:checked+.btn-primary:focus, .btn-check:active+.btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show>.btn-primary.dropdown-toggle:focus { box-shadow: 0 0 0 0.25rem rgba(191, 171, 96, .5); }
.btn-check:focus+.btn-primary, .btn-primary:focus { background-color: $paloma-primary; border-color: $paloma-primary; box-shadow: 0 0 0 0.25rem rgba(191, 171, 96, .5); }
.pagination-wrap { text-align: center; }
pre { margin: 75px -100px; }
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

/*- top-navbar -*/
header .navbar-nav {
    .nav-link { 
        &:last-child {
            @media (min-width: 577px) { padding-left: .5rem; padding-right: .5rem; }
            @media (max-width: 576px) { padding-left: .5rem; padding-right: 0rem; }
        }
    }
}

.navbar-light .navbar-nav { text-transform: uppercase; font-weight: bold;
    .nav-link.active { color: $paloma-primary; }
}
.navbar-light .navbar-toggler {
    color: rgba(0,0,0,0.25);
    border-color: rgba(0,0,0,0.2);
}
.navbar-light.scrolled { padding-bottom: .1rem; padding-top: .1rem; }
.navbar-brand img { transition: 400ms ease; height: 4.375rem;
    @media (max-width: 576px) { height: 3.8rem }
}
.scrolled .navbar-brand img { height: 3.375rem; }

/*- jumbotron -*/
.top-background-sub .img-responsive { width: 100%; }
.top-background { height: 50%; width: 100%; margin-bottom: 30px; background: #fafafa;
    h1.headline { font-size: 48px; margin-bottom: 0px; }
    .fa-external-link-alt, .fa-angle-double-right {
        @media (max-width: 576px) { display:none }
    }
    .btn-lg { background-color: $paloma-primary; text-decoration: none;
        &:hover, &:focus {background-color: #b2a066; border-color: #b2a066;}
    }
}
.top-background-sub { height: auto; width: 100%; margin-bottom: 30px; text-align: center;
    .img-responsive { display: table-cell; }
}

.banner {
    h3 { color: $paloma-secondary; padding-top:1rem; }
    a { text-decoration: none;
        &:hover, &:focus {
            h3 { color: $paloma-primary; }
        }
    }
}

.showcase {
    .showcase-text { padding:2rem;
        @media (min-width:768px) {padding:4rem }
        h2 { font-weight:100 }
        p.lead { font-size:1.10rem }
    }   
    .showcase-img { min-height:20rem; background-size:cover }
    .btn-primary { background-color: $paloma-primary; border-color: $paloma-primary; text-decoration: none;
        &:hover, &:focus { background-color: #b2a066; border-color: #b2a066; }
    }
    
}
.features-icons { padding-top:2rem;padding-bottom:2rem;
    .features-icons-item {
        .features-icons-icon { height:6rem }
        p.orgtoptxt { padding:0 0 0 28px }
        .nav-link { color:rgba(0,0,0,0.70) }
        .nav-link:hover { color:#077633; text-decoration:underline }
        .orgicon { font-size :1.25rem }
    }
}
@media (min-width:768px){
    .features-icons .features-icons-item .features-icons-icon img{ width:100% }
    .features-icons .features-icons-item h3 { font-size:1.75rem }
}
@media (max-width:767px){
    .features-icons .features-icons-item .features-icons-icon img{ width:75% }
    .features-icons .features-icons-item h3 { font-size:1.5rem }
}

/*- Modal -*/
.btn-close:focus { box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, .5); }
.prislista {list-style: none; padding-left: 0;
        li { border-bottom: dotted 1px #222; margin-top: 1rem;
            strong { float: right; }
        }
    }

.email-address:after {
    content: attr(data - name)
    "@"
    attr(data - domain)
    "."
    attr(data - tld);
}

/*- Formulär -*/
fieldset { min-width: 100%; }
form.form .btn { background-color: $paloma-primary; border-color: $paloma-primary; padding: .4rem 1.4rem;
    &:hover, &:focus {background-color: #b2a066; border-color: #b2a066;}
}
#website {display:none; }

/*- Accordion -*/
.accordion-button:focus {
    z-index: 3;
    border-color: #b2a066;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(191, 171, 96, .4);
}
.accordion-button:not(.collapsed) {
    color: #222;
    background-color: rgba(191, 171, 96, .1);
    box-shadow: inset 0 -1px 0 rgba(191, 171, 96, .4);
}
.accordion-button:not(.collapsed):after {
    background-image: escape-svg($accordion-button-icon);
}
.accordion-body { background-color:#fbfbfb; 
    h6 {color: $paloma-primary;}
}
.priserright {
    @media (max-width: 992px) {margin-top: -1px;}
}

/*- carousel -*/
.carousel { height: auto; margin-bottom: 25px;
    img { margin: 0 auto 0; }
}
.carousel-inner { overflow: initial; }
.carousel-fade {
    .carousel-inner {
        .item { transition-property: opacity; }
        .item, .active.left, .active.right { opacity: 0; }
        .active, .next.left, .prev.right { opacity: 1; }
        .next, .prev, .active.left, .active.right { left: 0; transform: translate3d(0, 0, 0); }
    }
    .carousel-control { z-index: 2; }
}
.carousel-caption { text-align: left; position: relative; margin-top: -175px; left: 0px; right: 20%; padding-bottom: 0px;
    h1 { font-size: 48px; margin-bottom: 0px; }
}

/*- footer -*/
.site-footer { padding-top: 2rem; line-height: 20px; --bs-bg-opacity: 1; background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)); color: var(--bs-gray-300);
    @media (max-width:414px) { padding-bottom: 2rem; }
    @media (max-width:376px) { padding-bottom: 2.5rem; }
    a { text-decoration: underline; text-decoration-color: $paloma-primary; text-decoration-thickness: .05em; text-underline-offset: 1.5px; color: var(--bs-gray-300);
        &:hover, &:focus { text-decoration-color: $paloma-secondary; color: var(--bs-gray-500); }
    }
    a.active { color: $paloma-primary; text-decoration-color: $paloma-secondary; }
    
}
.fa-ul { margin-left: 1.2rem;
    @media (max-width: 768px) { margin-left: 0rem;
    .fa-li { position: unset; margin-right: -.3rem; }
    }
    .fa-li.social { position: unset; margin-left: -1.5rem; margin-right: -.3rem;
        @media (max-width: 768px) { margin-left: -.5rem; margin-right: -.5rem; }
        }
}
.backtotop, .footmid { text-align: center; }
.backtotop { padding: 20px 0; clear: both; }
.btn-alt-paloma { background-color: $alt-paloma; border-color: $alt-paloma; text-decoration: none; color:#fff;  position: fixed; right: 1.2rem; bottom: 2rem; z-index: 99;
    @media (max-width:768px) { right: .6rem; bottom: 1.5rem; }
    @media (max-width:376px) { right: 50%; bottom: 0rem; transform: translateX(50%); margin-bottom: 1rem; }
    &:hover, &:focus { background-color: #430732; border-color: #430732; color:#dedede; }
    .btn-check:focus+.btn, &:focus { outline: 0; box-shadow: 0 0 0 0.25rem rgba(89, 9, 66, .25); }
}
.book-btn { color: #fff; background: $alt-paloma url(../img/bd-logo-min.svg) left no-repeat; background-position: .6rem .45rem; background-size: auto 24px; display: inline-block; text-align: center; vertical-align: middle; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; padding: .5rem 1.7rem .5rem 3rem; font-size: 1rem; line-height: 1.4; border: none; text-transform: uppercase;
        @media (max-width:768px) { padding: .5rem .8rem .5rem 3rem; }
}